import React from 'react'
import PageWrapper from '../../components/pagewrapper'
import Header from '../../components/header'
import Footer from '../../components/footer'

import Breadcrumb from '../../components/breadcrumb'
import {graphql, Link} from 'gatsby'

class Notfound extends React.Component {

  render() {
    let menu = this.props.data.drupal.menuByName.links
    let langcontainer=[{id:'EL', path:'/el'}, {id:'EN', path:'/en'}]

    var loc = []; //TODO pernaei san property sto breadcrumb, prepei na ginei function kai na to kano import giati einai idio se ola ta components
  var breadpath = this.props.location.pathname.split(/[/]/)
  breadpath.shift()

  breadpath.forEach((item,i) => {
    var value = null;
    var path = null;
    // console.log(item,i,loc[0])

    if(i === 0) {
      if(item === 'en' || item === 'el') {
        value = 'HOME';
        path=`/${item}`
      }
      else {
        value=item;
        path=`/${item}`
      }
      loc.push({value: value, path: path})
    }
    else {
      value=item;
      path=`${loc[i-1].path}/${item}`
      loc.push({value: value, path: path})
    }





  })
    return (
      <PageWrapper>
        <Header info={this.props.data.drupal.contactInfo} logourl={this.props.data.drupal.headerLogo.fieldLogoimage.entity.thumbnail.imageFile.publicURL} langcontainer={langcontainer} language='EL' path='/' menu={menu}/>
        <Breadcrumb background={this.props.data.drupal.background.fieldImage.imageFile.publicURL} title={`ΔΕΝ ΒΡΕΘΗΚΕ`} path={loc}/>

          <div className="section-full p-tb150">
                    <div className="container">
                        <div className="section-content">
                        	<div className="page-notfound text-center">
                            	<strong>ΔΕΝ ΒΡΕΘΗΚΕ</strong>
                                <span className="title">404</span>
                                <p>Η σελίδα δεν υπάρχει</p>
                                <Link className="site-button btn-effect" to={'/el'}>Πισω στην αρχικη</Link>

                            </div>
                        </div>
                    </div>
                </div>

                 <Footer social={this.props.data.drupal.social} logourl={this.props.data.drupal.footerLogo.fieldLogoimage.entity.thumbnail.imageFile.publicURL} language={`EN`} menu={menu}  info={this.props.data.drupal.contactInfo}/>
      </PageWrapper>
    )
  }
}

export default Notfound;


export const query = graphql`
  query {

    drupal {

      background: nodeById(id: "19") {

         ... on DRUPAL_NodePage {
           entityLabel
           entityId
           entityUrl {
             path
           }
           fieldImage {
               entity {
                 url
               }
               imageFile {
                 publicURL
               }
             }

         }


      }



      footerLogo:nodeById(id: "25") {
       ... on DRUPAL_NodeLogo {

         fieldLogoimage {
           entity {
             thumbnail {
               url
               alt
               imageFile {
                 publicURL
               }
             }
           }
         }
       }
     }
      headerLogo: nodeById(id: "24") {
   entityLabel
   ... on DRUPAL_NodeLogo {

     fieldLogoimage {
           entity {
             thumbnail {
               url
               alt
               imageFile {
                 publicURL
               }
             }
           }
         }
   }
 }
      contactInfo: nodeById(id: "26", language: EL) {
     title
     ... on DRUPAL_NodeContactInfo {
       fieldAddress
       fieldEmail
       fieldPhone
     }
   }
   menuByName(name: "main", language: EL) {
     links {
       label
       url {
         path
       }
     }
   }

   social:nodeQuery(filter: {conditions: [{field: "type", value: "Social_Links"}]}) {
      entities {
        ... on DRUPAL_NodeSocialLinks {

          fieldIcon
          fieldLink
        }
      }
    }








 }
  }
`
